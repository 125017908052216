.single_tecket{
    padding-top: 210px;
}

.single_tecket_item{
    height: 400px;
    position: relative;
}
.darck_fon{
    background-color: rgba(0, 0, 0, 0.558);
    height: 100%;
    width: 100%;
}

.single_tecket_item .container{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
}


.single_tecket_item .container h2{
    color: var(--main_font_color);
    letter-spacing: 10px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
}

.single_tecket_item .container p{
    letter-spacing: 2px;
    color: var(--second_font_color);
    text-align: center;
    font-weight: 500;
    text-transform: uppercase;
}

.line_div_darck_fon{
    display: flex;
    align-items: center;
    gap: 20px;
}



/* @media(max-width: 720px){
    .single_tecket_item .container h2{
        font-size: 25px;
    }

    .single_tecket_item .container p{
        font-size: 20px;
    }
}

@media(max-width: 480px){
    .single_tecket_item .container h2{
        font-size: 20px;
    }

    .single_tecket_item .container p{
        font-size: 16px;
    }
} */
