.verification_modal{
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    background-color: #e7e7e7ad;
    display: flex;
    justify-content: center;
    align-items: center;
}

.verification_modal_block{
    width: 635px;
    height: 435px;
    background-color: white;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
    animation: animVerifayModal .3s linear;
}

.verify_inputs_div{
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
}

.verify_inputs_div input{
    width: 40px;
    height: 40px;
    border:none;
    outline: none;
    text-align: center;
    font-size: 20px;
    background-color: #FAFAFA;
}

.verify_inputs_div input:focus{
    border: 2px solid #b28851;
}


.verification_btn{
    width: 150px;
    height: 44px;
    background-color: #D5AA72;
    border: none;
    outline: none;
    color: var(--main_font_color);
}

.verification_btn:hover{
    background-color: #B28851;
}

.verification_modal_block p {
    text-align: center;
}
.opt_div_verify{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
}

.verification_modal_block_title{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.verification_modal_block_title p:nth-of-type(1){
    font-weight: bold;
    
}

@keyframes animVerifayModal{
    to{
        transform: scale(1);
    }
    from{
        transform: scale(0);
    }
}