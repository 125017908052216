

.MyAccount_big_div {
  width: 100%;
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* padding-left: 60px; */
}
.MyAccount_title {
  min-width: 100px;
}

.MyAccount_big_div input{
  padding: 10px;
}
.edit-save-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 15px;
  width: 250px;
}

.edit-save-block span {
  color: #9ca3af;
  text-decoration: underline;
}

/* ///////////////////top inputes//////////////////////////// */
.top_inputes {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 250px;
}

.MyAccount_input-name {
  width: 100%;
  height: 40px;
  border: 1px solid #aaaaaa;
}
.MyAccount_input-name:focus {
  outline: transparent;
}
/* //////////// */
.MyAccount_input-surname {
  width: 100%;
  height: 40px;
  border: 1px solid #aaaaaa;
}
.MyAccount_input-surname:focus {
  outline: transparent;
}
/* //////////// */
.MyAccount_input-phone {
  width: 100%;
  height: 40px;
  border: 1px solid #aaaaaa;
}
.MyAccount_input-phone:focus {
  outline: transparent;
}
/* ////////////// */
.MyAccount_select-country {
  width: 100%;
  height: 40px;
  border: 1px solid #aaaaaa;
  color: #b26705;
}
.MyAccount_select-country:focus {
  outline: transparent;
}
/* /////////////// */
.MyAccount_input-date {
  width: 100%;
  height: 40px;
  border: 1px solid #aaaaaa;
  padding: 5px;
}
.MyAccount_input-date::-webkit-calendar-picker-indicator {
  filter: invert(50%) sepia(10%) saturate(10000%) hue-rotate(20deg) brightness(80%) contrast(90%);
  /* color: #B26705; */
}
/* .MyAccount_input-date::-webkit-calendar-picker-indicator {
  display: none;
}

.MyAccount_input-date {
  background-image: url('../../../images/CalendarInp.png');
  background-size: 16px;
  background-repeat: no-repeat;
  background-position: calc(100% - 8px) center;
} */
.MyAccount_input-date:focus {
  outline: transparent;
}
/* ///////////////////////// */
.gender_div {
  margin-top: 24px;
  width: 250px;
}
.gender_div p {
  color: #121314;
  margin-bottom: 24px;
}
/* //////// */
.gender_div_input {
  width: 16px;
  height: 16px;
}
.gender_div_input:checked {
  accent-color: #b26705;
}

.gender_div_inputes {
  display: flex;
  justify-content: space-between;
}

.gender_div_input_div {
  display: flex;
  align-items: center;
  gap: 8px;
}

.log_out_myAccount {
  display: flex;
  align-items: center;
  gap: 26px;
  margin-top: 34px;
  max-width: 100px;
  cursor: pointer;
}
.log_out_myAccount span {
  color: #a6a6a6;
}


.log_out_myAccount span:hover{
  color: #3F3D56;
}

.dropdown {
  width: 100%;
  position: relative;
  color: white;
}

.dropdown .label {
  color: #2b3044;
  margin-bottom: 5px;
}

.dropdown .select {
  cursor: pointer;
  transition: 0.3s;
  background-color: white;
  display: flex;
  /* padding: 10px; */
  padding-right: 10px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  border: 1px solid #aaaaaa;
  color: black;
}

.dropdown .select .selected {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
}


.dropdown .select .caret {
  margin-left: 10px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 6px solid black;
  transition: 0.3s;
}

.dropdown .select:hover {
  background-color: white;
}

.dropdown .menu {
  position: absolute;
    top: 50px;
    left: 0;
    z-index: 99;
    width: 100%;
    background-color: white;
    z-index: 99;
    padding: 20px;
    overflow: auto;
    height: 230px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.219);
}

.dropdown .menu li {
  display: flex;
    align-items: center;
    gap: 16px;
    height: 30px;
    padding: 20px 10px;
    cursor: pointer;
    color: #636363;
    border-bottom: 1px solid #ECEBEB;
}

.dropdown .menu::-webkit-scrollbar {
  width: 0;
}
.dropdown .menu li:hover {
  background-color: #F6F5F5;
}


.caret-rotate {
  transform: rotate(180deg);
}

.profile_edit_btn{
  padding: 3px 15px;
  background-color: #3F3D56;
  color: white;
  border: none;
  outline: none;
}


.MyAccount_big_div form{
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.profile_edit_btn_div{
  width: 250px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items:end;
}



.input_div{
  width: 250px;
  position: relative;
}

.input_div span{
position: absolute;
top: 10px;
right: 10px;
}
.input_div input{
  width: 100%;
  border: 1px solid #AAA;
  outline: none;
}


.input_div p{
  color: red;
}
@media (max-width: 680px) {
  .MyAccount_all {
    display: flex;
    justify-content: center;
  }
  /* .MyAccount_big_div {
    width: fit-content;
  } */


  .input_div, .profile_edit_btn_div, .gender_div .top_inputes, .edit-save-block{
    width: 100%;
  }
}



@media(max-width: 480px){
  .gender_div_inputes{
    flex-direction: column;
  }
}