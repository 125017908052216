
.select-lng{
  position: relative;
  cursor: pointer;
}

.select-lng span{
  color: var(--main_font_color);
}

.lng-list{
    position: absolute;
    top: 58px;
    left: -20px;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.563);
    border-radius: 8px;
    transform: rotateX(90deg);
    transition: all .5s;
    transform-origin: top center;
    width: 80px;
    padding-inline-start : 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100px;
    overflow: hidden;
}
.lng-list li{
  color: black;
  transition: all .6s;
  width: 100%;
  text-align: center;
  padding: 5px 20px;
  border-bottom: 1px solid #ECEBEB;
}

.lng-list li:hover{
  transform: scale(1.1);
  background-color: #F6F5F5;

}

.select-lng:hover ul {
  transform: rotateX(0);
}

@media(min-width: 1920px){
     #lng{
        font-size: 20px;
    }
  }


@media(max-width: 1920px){
     #lng{
        font-size: 17px;
    }
  }

@media(max-width: 1440px){
     #lng{
        font-size: 15px;
    }
  }


  @media(max-width: 1140px){
     #lng{
        font-size: 13px;
    }
  }