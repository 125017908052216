.slider {
    width: 100%;
    height: 740px;
    overflow: hidden;
    position: relative;
    top: -80px;
  }
  .slider .wrapper {
    height: 100vh;
    /* transition: all ; */
    will-change: transform;
  }
  .slider .arrows {
    position: absolute;
    top: 50%;
    background: none;
    height: 60px;
    border: 0;
    cursor: pointer;
    transition: ease 0.3s all;
    outline: none;
  }
  .slider .arrows.prev {
    left: 0;
  }
  .slider .arrows.prev:hover {
    opacity: 0.7;
    left: -10px;
  }
  .slider .arrows.next {
    right: 0;
  }
  .slider .arrows.next:hover {
    right: -10px;
    opacity: 0.7;
  }
  .slider .dots-container {
    height: auto;
    margin: 0;
    padding: 0;
    position: absolute;
    width: auto;
    text-align: center;
    left: 50%;
    bottom: 9px;
    transform: translateX(-50%);
    z-index: 10;
    list-style-type: none;
  }
  .slider .dots-container li {
    display: inline-block;
    padding: 5px;
  }
  .slider .dots-container li.active button {
    color: #00D8FF;
  }
  .slider .dots-container li button {
    color: #fff;
    background-color: transparent;
    border: none;
  }
  .slider .dots-container li button:hover {
    text-decoration: none;
    opacity: 0.7;
    cursor: pointer;
  }
  .slider .toggle-play {
    background: transparent;
    border: none;
    height: auto;
    position: absolute;
    width: auto;
    right: 5%;
    bottom: 9px;
    color: #3d3d3d;
    z-index: 1000000;
  }
  .slider .toggle-play:hover {
    text-decoration: none;
    opacity: 0.7;
    cursor: pointer;
  }
  .slider .each-slide {
    width: 100vw;
    height: 740px;
    float: left;
    font-size: 40vh;
    text-align: center;
    background-size: cover;
    background-position: center center;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
  }

  .slide_h1{
    color: var(--main_font_color);
    letter-spacing: 10px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .slider .each-slide.active .slide_h1 {
    animation: animTxt 2s linear;
  }

  .each-slide img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 900px;
    z-index: -3;
    animation: animImg 20s linear infinite;
    object-fit: cover;
    
  }

  .slider .dots-container li.active button span{
    color: var(--second_font_color);
  }


  @keyframes animTxt {
    to{
        opacity: 1;
        transform: translateY(0);
    }
    from{
        opacity: 0;
        transform: translateY(100px);
    }
  }

 @keyframes animImg{
  0%{
    transform: scale(1);
  }

  50%{
    transform: scale(1.2);
  }

  100%{
    transform: scale(1);
  }
 }
 @media(max-width: 720px){
  .slide_h1{
    font-size: 30px !important;
  }
 }


 @media(max-width: 720px){
  .slide_h1{
    font-size: 25px !important;
  }
 }