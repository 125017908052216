.reset-password-page{
    min-height: 100vh;
}


.reset-password-page .container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding-bottom: 700px;

    padding-top: 300px;
}


.reset_password_block{
    display: flex;
    flex-direction: column;
    gap: 50px;
    width: 318px;
}

.reset_password_form{
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}

.reset_password_form >div{
    width: 100%;
    position: relative;
}

.reset_password_title p{
    text-align: center;
}

.reset_password_form >div span{
    position: absolute;
    top: 13px;
    right: 10px;
}

.reset_password_form >div span:hover svg{
    fill: black;
}

.reset_password_form input {
    width: 100%;
    height: 44px;
    border: 1px solid gray;
    padding: 0 5px;
    outline: none;
}


.reset_password{
    width: 100%;
    height: 44px;
    background-color: #D5AA72;
    border: none;
    outline: none;
    color: var(--main_font_color);
}


.reset_password:hover{
    background-color: #B28851;
}

.inp_div p{
    color: red;
}