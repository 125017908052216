.shop_all {
  min-height: 100vh;
  position: relative;
  background-image: url('../../images/abouteUsBack.jpg');
    background-position: top;
    background-size: cover;
  padding: 80px 0;
}
/* .shop_top_titels{
  margin-bottom: 0px;
} */

.backImage_shop {
  background-image: url('./../../images/shop.jpg');
  filter: brightness(80%);
  background-repeat: no-repeat;
  height: 70vh;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin-bottom: 40px;
}

.form_shop {
  text-align: center;
  /* margin-top: 50px;
  margin-bottom: 50px; */
}
.container_shop{
  max-width: 1254px;
  margin: 0 auto;
}

/* ////////////select///////////////// */

.custom-select-container {
  position: relative;
}

.custom-select {
  /* padding-right: 30px; */
  /* appearance: none; */
  background-color: transparent;
  padding: 8px 20px;
  font-size: 16px;
  /* border: none; */
  /* border: 1px solid #b3b3b3; */
  border: 1px solid #DADADA;
  border-radius: 6px;
  outline: none;
  cursor: pointer;
  width: 290px;
  color: #5f5f5f;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

    /* Hide the default arrow */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* Add a custom arrow background image */
    /* background-image: url('../../images/logoAM.png');
    background-position: right center;
    background-repeat: no-repeat; */
    /* Adjust padding to make space for the custom arrow */
    padding-right: 30px; /* Adjust this value as needed */
}

.custom-select-container {
  position: relative;
}

.custom-select-arrow {
  position: absolute;
  top: 50%;
  right: 10px; /* Adjust this value to move the arrow left or right */
  transform: translateY(-50%);
  /* Adjust other styles for the arrow image */
  width: 20px; /* Adjust width of the arrow image */
  height: 20px; /* Adjust height of the arrow image */
  height: fit-content;
}


.custom-select:focus {
  color: #3f3d3d;
}

.shows_results_page_shop{
  color: #676767;
  padding: 24px 0 18px 32px;
}
/* /////////////////////////////////////// */

/* //////////////////input search/////////////////// */
.input-container-shop {
  position: relative;
  padding: 0;
  margin: 0;
  min-width: 180px;
  width: 300px;
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  gap: 12px;
}
.input-search-buttonn{
  /* font-size: 13px; */
  background-color: #D5AA72;
  color: #FFFFFF;
  padding: 10px 16px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}

.input-search-searchIcon {
  width: 100%;
  border: 1px solid #DADADA;
  border-radius: 6px;
  padding: 10px;
  min-width: 240px;
}
.input-search-searchIcon:focus {
  outline: none;
  /* border: none; */
}
.input-search-shop-button {
  position: absolute;
  bottom: 6px;
  right: 12px;
  border: none;
  background-color: transparent;
}
.input-search-shop-img {
  /* position: absolute;
  bottom: 12px;
  right: 12px; */
  cursor: pointer;
  width: 18px;
  height: 18px;
}
/* /////////////input_and_filteres//////// */
.input_and_filteres {
  display: flex;
  justify-content: space-between;
  padding-left: 30px;
  padding-right: 30px;
}

.fileress {
  display: flex;
  gap: 14px;
}

/* /////////////////shop box////////////////////// */
.shopess {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 40px;
  /* margin-top: 70px; */
  margin-bottom: 60px;
  /* padding: 20px; */
}
.shopess_start {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  gap: 40px;
  margin-top: 70px;
  margin-bottom: 60px;
  padding: 20px;
  margin-left: 30px;
}


.shop-box{
  width: 256px;
}

.shop-box_img {
  padding: 32px 25px;
  height: 273px;
  background-color: #D9D9D94D;
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 20px;
  position: relative;
}
.shop-box_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.shop-box_texts_div {
  margin: 8px 0 8px;
  text-align: start;
  cursor: pointer;
}
.shop-box-price {
  color: #D5AA72;
  margin-top: 10px;
}
/* .shop-box-title {
  font-size: 44px;
} */
/* /////////////////////////////// */
.shop-paginate {
  margin-bottom: 40px;
}

.shop-paginate-none {
  display: none;
}

.shop-box_img .souvenir_item_add_cart_div {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #E7E7E7BD;
  /* opacity: 1; */
  transform: rotateX(90deg);
  transform-origin: top center;
  transition: all 0.6s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.shop-box:hover .shop-box_img .souvenir_item_add_cart_div {
  transform: rotateX(0);
}

.shop-box_button{
  background-color: #D5AA72;
    color: white;
    border: none;
    cursor: pointer;
    width: 150px;
    padding: 10px 0;
    text-align: center;
}

.shop-box_button:hover{
  background-color: #B28851;
}
/* /////////////shop error text///////////// */
.shop_error_text {
  position: fixed;
  top: 70px;
  right: 5px;
  color: rgb(246, 31, 31);
  z-index: 1111;
  font-weight: 700;
}
.shop_error_text_none {
  position: absolute;
  top: 0;
  right: 0;
  color: rgb(246, 31, 31);
  z-index: 1111;
  display: none;
}
/* ////////////////// */


.all_Shop_Content{
  background-color: #FFFFFF;
  padding: 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.shop_all .container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
    padding-bottom: 100px;
    padding-left: 86px;
    padding-right: 86px;
    gap: 32px;
}



.shop_museum_filter, .shop_category_filter{
  width: 225px;
    height: 40px;
    border-radius: 6px;
    position: relative;
}


.shop_museum_filter_inp_div, .shop_category_filter_inp_div{
  position: relative;
  width: 100%;
}


.shop_museum_filter_inp_div >span, .shop_category_filter_inp_div >span{
  position: absolute;
  top: 13px;
  right: 15px;
}

.shop_museum_filter_inp_div >input, .shop_category_filter_inp_div >input{
  width: 100%;
    height: 100%;
    padding: 10px 16px;
    outline: none;
    border: 1px solid#DADADA;
    border-radius: 6px;
}

.category_filter_item_p{
  height: 100%;
  width: 100%;
}

@media screen and (max-width: 1180px) {
  .input_and_filteres {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 35px;
  }
  .shopess {
    margin-top: 40px;
  }
}



/* ========================== */



@media screen and (max-width: 910px) {
  .shopess_start {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 40px;
    margin-top: 70px;
    margin-bottom: 60px;
    padding: 20px;
    /* margin-left: 30px; */
  }
}

@media screen and (max-width: 880px) {
  .input_and_filteres {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 35px;
  }
  .shopess {
    margin-top: 40px;
  }

  .shop_all .container{
    padding-left: 40px;
    padding-right: 40px;
  }
}


@media screen and (max-width: 690px) {
  .fileress {
    display: flex;
    flex-direction: column;
    gap: 45px;
  }
  .shop-box {
    width: 300px;
  }

  .shop_all .container{
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media screen and (max-width: 420px) {
  .input-container-shop{
    flex-direction: column;
  }
}
