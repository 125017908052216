
.home_page{
/* position: absolute;
top: -100px;
width: 100%; */
min-height: 100vh;
background-color: #fafafa;
}



@media(max-width: 1024px){
    .home_page{
    
        position: static;
        top: 0;
        left: 0;
    }
}